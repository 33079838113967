%title-style{
    font-size: clamp(20px, 2vw, 30px);
    font-family: $font-family-heading;
    margin: 4vh  0 4vh 0;
    span{
        color: $rouge 
    }
}

%subtitle-style{
    font-family: $font-family-subtitle;
    font-size: clamp(15px, 1.4vw, 28px);
 
    margin: 2vh 0 4vh 0;
}

%input{
    display: block;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    min-width: 8vw;
    border: none;
    border: 2px solid $saumon;
    padding: 0.6rem 0 0.6rem 0.4rem;
}

%pagination-style{

        display: flex;
        justify-content: end;
        gap: 5px;
        margin-top: 2rem;
    
        &__number {
            background: none;
            border: 1px solid $rouge;
            padding: 5px 10px;
            cursor: pointer;
    
            &.active {
                background-color: $rouge;
                color: #fff;
            }
        }
    
}

@keyframes levitate {
    0% {
        transform: translateY(0);
    }


    40% {
        transform: translateY(-20px);
    }

    60% {
        transform: translateY(-10px);
    }

    80% {
        transform: translateY(-15px);
    }

    100% {
        transform: translateY(0);
    }
}