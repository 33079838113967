/* Modal.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 58968498;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  

  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #666;
  }
  
 
  .modal {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2); 
    position: relative;
    opacity: 0;
    padding: 3vw 2rem;
    min-width:80%;
    transform: translateY(-50%);
    transition: opacity 0.3s ease, transform 0.3s ease;
    display: flex;
    flex-direction: column;
    background-color: $background;
    z-index: 200484874847897489;


  }
  
  .modal-overlay.active .modal {
    opacity: 1;
    transform: translateY(0);
  }
  

  @media screen and (min-width : 670px) {
    .modal{
      min-width:50%;
    }
  }

  @media screen and (min-width : 1200px) {
    .modal{
      min-width:30%;
    }
  }