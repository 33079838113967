

.coworker-header{


    display: flex;
    justify-content: space-between;
    font-family: 'Montserrat', sans-serif;
    margin-bottom:10vh;

    &__add-button{

        background-color: $saumon;
        cursor: pointer;
        display: flex;
        justify-content: center;
  
        align-items: center;
  
        color: $blanc;
        text-align: center;
    
    
        border-radius: 3px;;

        img{
            width:2rem;
        }
    }

    &__input{
        
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        min-width: 15vw;
        border: none;
        border-bottom: 2px solid $saumon;
        padding: 0.6rem 0 0.6rem 0.4rem; 
    }
}

.coworkers{
   

    .pagination{
        display: flex;
        justify-content: end;
        gap: 5px;
        margin-top: 2rem;
    
        &__number {
            background: none;
            border: 1px solid $rouge;
            padding: 5px 10px;
            cursor: pointer;
    
            &.active {
                background-color: $rouge;
                color: #fff;
            }
        }
    }
}