.footer {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: clamp(12px, 1vw, 17px);
    color: $rouge;
    font-display: swap;
    position: absolute; /* position fixe pour rester toujours visible */
    // bottom: 2vh;
    left: 50%;
    width: 80%;
    transform: translateX(-50%);
    padding: 2vh 0; /* Ajouter un padding en haut pour créer de l'espace */
    box-sizing: border-box; /* Assurer que le padding est inclus dans la largeur totale */
}
