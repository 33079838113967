
.alert{
    position: absolute;
    left: 50%;
    right: 50%;
    top: 5%;
    transform: translate(-50%, -50%) ;
    display: flex;
    font-display: swap;
    align-items: center ;
    gap:2rem;
    font-family: 'Poppins', sans-serif;
    font-size: clamp(13px, 1.1vw, 20px);
    color : $blanc;
    min-width: 80%;
    border-radius: 5px;
    padding: 1.14rem 1rem 1.4rem 1rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    &--success{
        background-color: $vert
    }

    &--echec{
        background-color: $rouge;
    }


    &__icon{
        width: 2rem;
        fill: #fff
    }
}

@media screen and (min-width : 765px) {
    .alert{
        min-width: 39%;
    }
}