$rouge : #BF2626;
$rouge-fonce : #820123;
$saumon : #CC8E8E ;
$background : #FBF8F8;
$blanc : #FFFFFF;
$vert : #44A677;
$noir :  #333333;
$gris : #DACACA;
$comp1 : mix(rgba(236,133,133,0.35), rgba(17, 7, 7, 0.5));

$comp1 :   #BD9A9A;

$principal-text-color : #333333;


$font-family-title : "General Sans", sans-serif;
$font-family-text : "Montserrat", sans-serif;
$font-family-heading: "Satoshi", sans-serif;
$font-family-subtitle : "Open Sans", sans-serif


