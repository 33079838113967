* {
    box-sizing: border-box;

    &::after,
    &::before {
        box-sizing: border-box;
    }


}

img {
    max-width: 100%;
}

html {
    font-size: 62.5%;
    height: 100%;
}

body {
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 1.2rem;
    background-color: $background;

    
}

a,
a:hover,
a:visited {
    text-decoration: none;
   
}





@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Poppins&family=Lato&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Salsa&display=swap');
