.menu {
    display: flex;
    position: relative;
    z-index: 121218;
    flex-direction: column;
    background-color: $rouge-fonce;
    color: #fff;
    position: fixed;
    width: 100%;
    height: 100%;
    left: -100%;
    transition: all .2s ease-out;
    padding: 3rem 0;

    &.open {
        left: 0;
        transition: all .2s ease-out;
    }

    .coworker {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;

        &__profil {
            border-radius: 50%;
            width: 100px;
            height: 100px;
            object-fit: cover;
        }

        &__name {
            font-size: 30px;
            text-align: center;
            font-display: swap;
            font-family: 'General Sans', sans-serif;
            font-weight: 500;
            margin-bottom: 1vh;
        }

        &__job {
            font-size: 1.7rem;
            text-align: center;
            font-family: 'Montserrat', sans-serif;
        }
    }

    &__close {
        width: 3rem;
        margin: 2rem 0 0 3rem;
        fill: $blanc;
    }

    &__list {
        display: flex;
        flex-direction: column;
        align-items: center;
  
        margin: auto 0;

        
    }

 

    &__icon {
        width: 1.5vw;
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $gris;
        padding : 2rem 0;
        font-family: $font-family-title;
        font-size: clamp(20px, 2vw, 50px);
        gap: 0.5vw;
        border-bottom: 1px solid $gris;
        text-align: center;
        cursor: pointer;
        width: 60%;

        &:last-child {
            border-bottom: none;
        }

        svg {
            display: none;
        }
    }

    &__logo {
        width: 10rem;
        margin: 0 auto;
    }
}

.active {
    color: $rouge;
}

@media screen and (min-width: 670px) {
    .menu {
        position: fixed;
        padding: 0;
        top: 0;
        left: 0;
        bottom: 0;
        width: 15%;
        background-color: $background;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        .coworker {
            flex-direction: row;
            gap: 2rem;
            padding: 1.4rem 0.8rem;
            background-color: $rouge-fonce;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

            &__profil {
              
                width: 70px;
                height: 70px;
            }

            &__name {
                font-size: clamp(12px, 1.2vw, 20px);
                font-display: swap;
                font-family: 'General Sans', sans-serif;
                font-weight: 500;
                margin-bottom: 1vh;
            }

            &__job {
                font-size: clamp(12px, 0.9vw, 17px);
                font-display: swap;
                font-family: 'Montserrat', sans-serif;
            }
        }

        &__close {
            display: none;
        }

        &__item {
            font-size: clamp(11px, 1.1vw, 17px);
            text-align: center;
            margin: auto auto;
            padding : 1.87rem 0 1.87rem 15px;
            justify-content: flex-start;
            border-bottom: 1px solid $gris;
            color : rgba(191, 38, 38, 0.20);
            width: 100%;
            svg {
                display: block;
            }
        }

        &__list {
            margin-top: 3vh; ;
            justify-content: flex-start;
        }

        &__logo {
            margin: auto auto 0 auto;
        }
    }
}

.active {
    color: $rouge;
}