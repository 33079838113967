
.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    padding: 1rem 2.2rem;
    flex : 1 0 80%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    &__logo{
        display: none;
    }

    &__menu{
        font-family: $font-family-heading;
        font-display: swap;
        
        font-size: clamp(20px, 1vw, 50px);
        cursor: pointer;
    }

    &__actions{
        display: flex;
        justify-content: center;
        gap:1rem;
        img{
            cursor: pointer;
            &:first-child{
                width: 2rem;
            }

            &:hover{
                color: $rouge;
            }

        }
    }
}

@media screen and (min-width : 670px) {

    .header{
        &__menu{
            display: none;
        }

        &__logo{
            display: block;
            width: 2vw;
        }
    }
 
}