
.login{
    position: absolute;
    top: 0; bottom: 0; left: 0; right: 0;
    height: 100%;
    margin:0;
    padding:0;
    background-size: 100% ;
    width:100%;  
    display: flex;
    justify-content: center;
    align-items: center;

    &::before{
        background-image: url('../../assets/coworking.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        height:100vh;
        // filter: blur(7px);
        // -webkit-filter: blur(3px);
        content: "";
        position: fixed;
        height: 100%; width: 100%;
        z-index: -1;
    }
  
    
    &__image{
        display: none;

    
    }

    &__logo{
        width:clamp(100px,10vw, 450px);
        margin-bottom:5rem;
    }

    &__main{
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        background-color: $blanc;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        padding: 5rem 2em;
        margin : 0 3rem;
        border-radius: 10px;
        form {
            display: flex;
            justify-content: center;
            align-items:normal;
            flex-direction: column;
            gap:3vh;
     
        }
    }

    &__title{
        font-size: clamp(14px, 1.2vw, 20px);
       
        font-family: 'Poppins', sans-serif;
        font-display: swap;
        text-align: center;
        margin-bottom:2.7rem;
        span{
            color : $rouge;
        }
    }

    &__subtitle{
        margin-bottom:5rem;
    font-size: 2.5rem;
        font-family: 'Poppins', sans-serif;
        font-display: swap;
        font-weight: 600;
    }

    &__error{
       
        display: flex;
  
        align-items: center;
        color : $rouge;
        gap:1rem;
        width: 100%;
        margin-bottom:2rem;
        font-size: clamp(13px, 1vw, 19px);
        font-family: 'General Sans', sans-serif;
    }

    .form{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &__group{
            min-width: 100%
        }
    }

    &__input{
      
        background-color: #F0EEEE;
        border: none;
        border-bottom: 2px $rouge solid;;
        
  
        font-size: 1.5rem;
        font-family: 'Roboto', sans-serif;
        font-display: swap;
        padding:1rem 0rem 1rem 1rem;
        width: 100%;
    }

    &__submit {
        background-color: $rouge;
        cursor: pointer;
        color: $blanc;
        border: none;
        width: fit-content;
        margin: auto;
        padding: 1rem 3rem;
        font-size: clamp(13px, 1vw, 19px);
        font-family: 'General Sans', sans-serif;
        font-display: swap;
        border-radius: 3px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

        display: flex;
        justify-content: center;
        align-items: center;
        gap:1em
    }
}

@media screen and (min-width : 670px) {

    .login{
 
        &__main{
            margin: 0
        }
        &__image{
            display: block;
            width:50%;
            height:100vh;
            object-fit: cover;
        }

        // &__title{
        //     width: 50%;
        // }

        &__main{
            margin: auto auto;;
            width: 35%;
        }

        // &__input{
        //     max-width:30vw;
        // }

      
    }

}