.layout{
    display: flex;
    
    &__logout{
        display: flex;
        flex-direction: column;
        gap: 5vh;

        .title{
            font-family: $font-family-title;
            font-display: swap;
            font-size: clamp(12px, 1.2vw, 20px);
        }

        .validate{
            font-family: $font-family-heading;;
            font-display: swap;
            font-size: clamp(12px, 1vw, 20px);
            background-color:$saumon;
            color: $blanc;
            width: fit-content;
            padding: 0.5rem 1rem;
            border-radius: 2px;
            
        }
    }


    
}

.loader{
    background-color: #b50024;
    position: absolute;
    bottom: 0;
    top: 0;
    z-index: 10000000;
    width: 100%;
    max-height: 100%;
    display: flex;
    font-family: 'Poppins', sans-serif;
    font-size: clamp(12px, 1.2vw, 20px);
    justify-content: center;
    align-items: center;
   flex-direction: column;

    &__content{
        // animation: levitate 4s  ease-in infinite;
        font-size: clamp(12px, 1.4vw, 25px);
        color: $blanc;
        display: flex;
        gap: 1rem;
        justify-content: center;
        align-items: center;
    }
    .logo{
      
        width: clamp(100px, 23vw, 400px);
      
    }
  
}

.container{

    margin: 0;
    width: 100%;
    min-height: 100vh;
    position: relative;
}

.main{
    padding : 3rem 2rem;
    position: relative;
    &__actions{
        display: flex;
        justify-content: flex-end;
        gap:1vw;
        align-items: center;
    }

    &__action{

        font-size: clamp(12px, 1vw, 20px);
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        min-width: 8vw;
        text-align: center;
        border: none;
        background-color:$saumon;
        color: $blanc;
        padding: 0.6rem 0 0.6rem 0.4rem;

    }
}


@media screen and (min-width : 670px) {
    .container{
        margin-left: 15%;
    }
}
