.form {
   
  font-size: clamp(11px,1.0vw, 17px);
  display: flex;
  flex-direction: column;
  gap:1.87rem;
  font-family: $font-family-text;
  font-display: swap;

    &__title{
        font-size: clamp(14px,1.5vw, 30px);
        margin:2vw 0 3vh  0;
        color: $rouge;
        position: relative;
        font-family: 'General Sans', sans-serif;
        &::before{
          position: absolute;
          display: block;
          content: "";
          background-color: $gris;
          bottom: -1vh;
          width: 90%;
          height: 1px;
        }
    }

    &__eyeIcon {
      width: 2rem;
      position: absolute;
      right:3%;
      top:25%;
      cursor: pointer;

    }
    
    &__radioButton{
      display: flex;
      flex-direction: column;
    }

    &__group{
      position: relative;
      max-width: 350px;
      display: flex;
      flex-direction: column;
      gap:5px;
    

      &--password{
        position: relative;
        flex-grow: 1;
        
      }

      &--row{
        flex-direction: row;
        align-items: flex-start;
        
      }
    }

    &__generate{
      background-color: $rouge;
      color: #fff;
      font-family: $font-family-title;
      padding: 0.8rem 1.7rem;
      cursor: pointer;
    }

    &__label{
      font-weight: 500;
    }
   

    &__input{
        
        width: 100%;
        border: 1px solid $gris;
        border-radius: 4px;
        padding: 1rem 0 1rem 0.4rem; 
        font-size: clamp(11px,1vw, 20px);
        font-family: $font-family-title;
        
        &--backgroundcolor{
          border: none;
          background-color: #e2d7dad1;
          font-family: $font-family-title;
        }
    
    }

    &__actions{
      display: flex;
      gap:1rem
    }

    &__action{
      
      padding: 0.5rem 1rem;
      color: $blanc;
      cursor: pointer;
      background-color: $saumon;
      font-family: 'Montserrat', sans-serif;
      font-size: clamp(11px,1vw, 20px);
      border: none;
      width: fit-content;
    }

    &__submit{
        width: fit-content;
        background-color: $rouge;
        color: $blanc;
        padding: 1rem 2.35rem;
        font-size: clamp(11px,1.1vw, 20px);
        border: none;
        cursor: pointer;
    }

    &__time{
      display: flex;
      justify-content: space-between;
      font-size: clamp(11px,1.1vw, 15px);
      font-family: 'Montserrat', sans-serif;
    }


    select option:disabled {
      color: #888;
    }
  }