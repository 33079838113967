.main{

    &__back{
        display: flex;
        gap:1.5rem;
        align-items: center;
        border: 2px solid $rouge;
        border-radius: 30px;
        width: fit-content;
        transition: all .3s ease-in-out;
        padding : 0.5vh 2rem 0.5vh 1.5rem;
        color : $rouge;
        cursor: pointer;
        font-family:'Poppins', sans-serif ;
        font-size: clamp(12px, 1vw, 20px);

        &:hover{
            color: $blanc;
            background-color: $rouge;
            transition: all .3s ease-in-out;
        }
    }


    &__search{
        display: flex;
        justify-content: space-between;
        align-content: center;

        &-input{
            
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            min-width: 15vw;
            border: none;
            border-bottom: 2px solid $saumon;
            padding: 0.6rem 0 0.6rem 0.4rem; 
        }
    }


    &__actions{
        display: flex;
        justify-content: flex-end;
        gap:1vw;
        align-items: center;
        cursor: pointer;
    }

    &__action{

        font-family: 'Montserrat',sans-serif;
        font-display: swap;
        font-size: clamp(12px, 1vw, 20px);
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        min-width: 8vw;
        text-align: center;
        border: none;
        background-color:$saumon;
        color: $blanc;
        padding: 0.6rem 0.7rem 0.6rem 0.7rem;

        

    }

    &__offices{
        display: flex;
        flex-wrap: wrap;

        gap:3rem;

        .office{
            min-width: 22%;
            font-family:'Poppins', sans-serif ;
            font-size: clamp(12px, 1.2vw, 16px);
           
            padding: 1rem 2.3rem;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;


            .workspaces{
                display: flex;
                flex-direction: column;
                gap:5px;;
            }

            .workspace{
                display: flex;
                gap:1rem;
                justify-content:space-between ;
                align-items: center;

                &__status{
                    background-color: $rouge;
                    width:12px;
                    height: 12px;
                    border-radius: 50%;

                 &.free{
                    background-color: $vert;
                 }

                 &.busy{
                    background-color: $rouge;
                 }
                }

                &__edit{
                    width: 1.5rem;
                    cursor: pointer;
                }

                div{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap:3px;
                }
            }
            &__title{
                font-family : $font-family-title;
                font-size: clamp(12px, 1.5vw, 17px);
                margin-bottom:3.5rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                // background-color: $rouge;
                // color:$blanc
            }
        }
    }


    &__add{
        cursor: pointer;
        background-color: $rouge;
        font-family: 'Poppins', sans-serif;
        font-display: swap;
        font-size: clamp(12px, 1.1vw, 17px);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.2s ease-in-out;
        color: $blanc;
        border-radius: 3px;;
        padding: 0.5rem 0.5rem;
        gap: 10px;

        // &:hover{
        //     transform: scale(1.1);
        //     transition: all 0.2s ease-in-out;
        // }
       
        img{
            width:1.3rem;
        }
    }

    &__filters{
       
        margin-left: auto;
        display: flex;
        justify-content: flex-end;
        gap:1vw;
        align-items: stretch;
    }

    &__filter{
        display: block;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        min-width: 8vw;
        border: none;
        border: 2px solid $saumon;
        padding: 0.6rem 0 0.6rem 0.4rem; 
        &--hidden{
            display: none;
        }
      
    }

    &__heading{

       @extend %title-style;
    }

    .pagination{
        display: flex;
        justify-content: end;
        gap: 5px;
        margin-top: 2rem;
    
        &__number {
            background: none;
            border: 1px solid $rouge;
            padding: 5px 10px;
            cursor: pointer;
    
            &.active {
                background-color: $rouge;
                color: #fff;
            }
        }
    }
}


@media screen and (min-width : 670px) {

    .main{
        &__filter{
            
            &--hidden{
                display: block;
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                min-width: 8vw;
                border: none;
                border: 2px solid $saumon;
                padding: 0.6rem 0 0.6rem 0.4rem; 
            }
        }
    }

}