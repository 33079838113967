.main{

    &__table{
            &-head{

            th{
                background-color: $saumon;
                font-family: 'General Sans', sans-serif;
                font-display: swap;
                font-size: clamp(12px, 1.1vw, 17px);
                color: $blanc;
                min-width: 10vw;
                text-align: left;
                border-right: 1px solid $gris;
                padding: 1.4rem 5px;;
            }
        }
    
        td{
            font-size: clamp(10px, 1vw, 15px);
            padding: 0.7rem 1rem;
            font-display: swap;
        
            font-family: 'Montserrat', sans-serif;
         
            justify-content: space-between;

            &.status-cell{
                display: flex;
                align-items: center;
                justify-content: space-between;

            }
            
            &.red{
                color: $rouge
            }

            &.green{
                color : $vert;
            }

            .minus{
                display: inline-block;
                width: 10px;
                height: 3px;
        
                background-color: $noir;
                cursor: pointer;
                transition: all .2s ease-in-out;
                &:hover{
                    background-color: $rouge;
                    transition: all .2s ease-in-out;
                }
            }
        }

        tr{
            border-bottom: 1px solid $gris;
        }

        img{
            width:clamp(50px,2.5vw, 200px)
        }

        .actions{
    
            img{
                width: 2rem;
                cursor:pointer
            }
        }
    }
 
}