.main{
 

    &__workspaces{
        padding: 3rem;
        background-color: $background;
        display: flex;
        flex-direction:column ;
        gap:1rem;
        width: fit-content;
        font-family: 'Montserrat',sans-serif;
        font-display: swap;
        font-size: clamp(12px, 1vw, 20px);
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        color: $noir;

        .workspace{
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap:10rem;
            &__status{
                background-color: $rouge;
                width:20px;
                height: 20px;
                border-radius: 50%;
            }
        }
        
    }



    .validation{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1.7rem;
        font-family: 'Montserrat', sans-serif;
        &__info{
            font-size: clamp(13px, 1vw, 17px);
            font-weight: 500
        }

        &__icon{
            cursor: pointer;
            width: 2.8rem;
            transition: 0.2s all ease-in-out;

            &--selected{
                transform : scale(1.2);
                transition: 0.2s all ease-in-out;
            }
        }

        &__icons{
            display: flex;
            gap:1rem;
            align-items: center;
        }

        &__action{

            border: none;
            background-color: $saumon;
            cursor: pointer;
            font-size: clamp(12px, 1vw, 20px);
            color: $blanc;
            text-align: center;
            font-weight: 500;
            padding: 0.5rem 2rem;
            border-radius: 3px;;
            width: fit-content;
        }
    }
}

.coworker{

    &__title{
        @extend %subtitle-style;
        
    }

    &__message{
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &__error{
        font-family: 'Montserrat',sans-serif;
        font-size: clamp(12px, 1vw, 20px);
    }

    &__action{
        font-family: 'Montserrat',sans-serif;
        border: none;
        background-color: $saumon;
        cursor: pointer;
        font-size: clamp(12px, 1vw, 20px);
        color: $blanc;
        text-align: center;
        font-weight: 500;
        padding: 0.5rem 2rem;
        border-radius: 3px;;
        width: fit-content;
    }


    &__section{
        display: flex;
        flex-direction: column;
        gap:0;
    
    }

    &__notes{
        min-height: 8vh;
        border-radius: 3px;
        background-color: rgba(162, 159, 159, 0.1);
        width: 100%;
        padding: 1rem 1rem;

    }

    &__image{

    
        max-height:5vh;
        object-fit: cover;
        margin-bottom: 4vw;;
    }

    &__info{
        font-family: 'Montserrat',sans-serif;
        font-display: swap;
        font-weight: 500;
        font-size: clamp(12px, 1vw, 20px);
        display: flex;
        align-items: center;

        &--column{
            flex-direction: column;
            align-items: start;
        }
            
        span{
            color: $rouge;
        
             
        }
    }

    &__icon{

        width: 4.5rem;
    }

    &__infos{
        display: flex;
       flex-direction: column;
       gap:1vh;
   }


   &__currentBooking{

        .validation{
            margin-top: 1vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 1.7rem;
            font-family: 'Montserrat', sans-serif;
            font-display: swap;

            &__attendance{
                display: flex;
                font-family: 'Poppins', sans-serif;
                gap: 1rem;
                align-items:center;
            }

            &__date{
                display: flex;
                align-items: center;
            }
            &__info{
                font-size: clamp(13px, 1vw, 17px);
                font-weight: 500
            }

            &__icon{
                cursor: pointer;
                width: 2.8rem;
                transition: 0.2s all ease-in-out;

                &--selected{
                    transform : scale(1.2);
                    transition: 0.2s all ease-in-out;
                }
            }

            &__icons{
                display: flex;
                gap:1rem;
                align-items: center;
            }

            &__action{

                
                background-color: $saumon;
                cursor: pointer;
                font-size: clamp(12px, 1vw, 20px);
                color: $blanc;
                text-align: center;
                font-weight: 500;
                padding: 0.5rem 2rem;
                border-radius: 3px;;
                width: fit-content;
            }
        }

        .completed{
            color: red;
            font-size: clamp(12px, 1vw, 20px);
            font-family: 'Poppins', sans-serif;
            margin-top:2rem;
        }

        .bookingStatus{
            display: flex;
           flex-direction: column;
           
            gap: 1rem;
        }

        .buttonNew{
            border: none;
            background-color: $saumon;
            cursor: pointer;
            font-size: clamp(12px, 1vw, 20px);
            color: $blanc;
            text-align: center;
            font-weight: 500;
            padding: 0.5rem 2rem;
            border-radius: 2px;;
            width: fit-content;
            font-family: 'Poppins', sans-serif;
        }

   }



}

@media screen and (min-width : 670px) {
    .coworker{
        &__section{
            gap:10rem;
            flex-direction: row;
        }
    }
}