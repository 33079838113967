

.dashboard{
    padding : 3rem 2rem;
    &__title{
        @extend %title-style
    }

    &__content{
        display: flex;
        justify-content: space-between;
        flex-direction: column-reverse;
        margin-bottom:4vh;
            .reservations{
                display: flex;
                flex-direction: column;
                background-color: $blanc;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
                border-radius: 10px;
                padding: 1.6rem 3rem 1.6rem 1.6rem;
                width: fit-content;
                gap:1.5rem;

                &__title{
                    font-family: 'Montserrat', sans-serif;
                    font-size: clamp(12px, 1.3vw, 25px);
                    font-weight: 700;
                    span{
                        color: $rouge;
                    
                    }
                }

                &__item{
                    display: flex;
                    font-family: 'Montserrat', sans-serif;
                    font-size: clamp(12px, 1vw, 20px);
                    font-weight: 500;
                    gap: 10px;

                    div{
                        min-width: 18vw;
                        display: flex;
                        justify-content: space-between;
                        gap:5rem;
                        color:$blanc;
                        background-color: $saumon;
                        padding: 0.5rem 0.73rem;
                        border-radius: 3px;
                    }
                }
        }

        .actions{
            font-family: 'Montserrat', sans-serif;
            display: flex;
            flex-direction: column;
            gap:1.5rem;
            width: 25vw;
            &__item{
                background-color: $saumon;
                cursor: pointer;
                font-size: clamp(12px, 1vw, 20px);
                color: $blanc;
                text-align: center;
                font-weight: 500;
                padding: 0.5rem 0;
                border-radius: 3px;
                margin-bottom:2rem;
                width: 100%;
                &--hidden{
                    display: none;
                }

            }
        }
    }

    &__table{
        overflow: auto;
        min-height: 15vh;;
        margin-bottom:7vh;
        width: fit-content;

        &::-webkit-scrollbar {
            display: none; /* For Chrome, Safari, and Opera */
        }

        th{
            background-color: $saumon;
            font-family: 'General sans', sans-serif;
            font-weight: 500;
            font-size: clamp(12px, 1.1vw, 17px);
            color: $blanc;
            min-width: 10vw;
            text-align: left;
            border-right: 1px solid $gris;
            padding: 1.4rem 5px;;
            &.job, &.type, &.start_date{
                display: none;
            }
        }

        td{
            font-size: clamp(12px, 1vw, 15px);
            padding: 0.7rem 1rem;
            font-family: 'Montserrat', sans-serif;
            font-display: swap;
            &.type, &.start_date, &.job{
                display: none;
            }

            a,
            a:hover,
            a:visited {
                text-decoration: none;
                color: $noir
            }

            
        }

        img{
            width:clamp(50px,2.5vw, 200px)
        }

        .actions{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            img{
                width: 2rem;
                cursor:pointer
            }
        }
    }


    &__empty{

        font-family: $font-family-title;
        font-display: swap;
        font-size: clamp(15px, 1.5vw, 25px);
        text-align: center;
        margin-top: 7vh;
        
    }


    .birthdays{
        margin-bottom:5rem;
        max-width: 500px;
        &__title{
            font-family: $font-family-heading;
            font-size: clamp(15px, 1.5vw, 25px);
            padding-bottom:3rem;
        }


        &__list{
            padding: 1rem 4rem 1rem 1rem;
            max-height: 20vh;
            overflow-x: auto;
            scrollbar-color: $rouge;

            display: flex;
            flex-direction: column;
            gap:3rem;

            &::-webkit-scrollbar {
                width: 12px; /* Largeur de la scrollbar */
            }
        
            &::-webkit-scrollbar-track {
                background-color: #f1f1f1; /* Couleur de la piste de la scrollbar */
            }
        
            &::-webkit-scrollbar-thumb {
                background-color: $rouge; /* Couleur de la scrollbar */
                border-radius: 10px; /* Arrondir les bords de la scrollbar */
                border: 3px solid #f1f1f1; /* Ajouter une bordure pour la scrollbar */
            }
        }


        .coworker{
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            display: flex;
            align-items: center;
            gap:5rem;
            border-radius: 10px;
            padding: 1rem 1rem;
          

            &__profil{
                width: 6rem;
                height: 6rem;
                border-radius: 50%;
                border : 1px solid $rouge;
                object-fit: cover;
            }

            &__infos{
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-size: clamp(12px, 1vw, 17px);
                font-family: $font-family-text;
                font-display: swap;
            }
            
        }
        
    }

  
}



@media screen and (min-width : 670px) {

    .dashboard{
        &__content{
            flex-direction: row;
            .actions{
             
                &__item{
                    margin-bottom: 0;
                    &--hidden{
                        display: block;
                    }

                }
            }
        }

        &__table{
            td{
                &.job, &.type, &.start_date{
                    display: table-cell;
                }
            }

            th{
                &.job, &.type, &.start_date{
                    display: table-cell;
                }
            }
        }
    }



}