@font-face {
    font-family: 'General Sans';
    src: url('../../assets/fonts/GeneralSans-Medium.woff') format('woff'),
         url('../../assets/fonts/GeneralSans-Medium.woff2') format('woff2');
}

@font-face {
    font-family: 'Satoshi';
    src: url('../../assets/fonts/Satoshi-Bold.woff') format('woff'),
         url('../../assets/fonts/Satoshi-Bold.woff2') format('woff2');
}